<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.76592 0.708951C7.91684 0.652355 8.08316 0.652355 8.23408 0.708951L13.5674 2.70895C13.8276 2.80653 14 3.05527 14 3.33317V7.99984C14 10.2975 12.4788 12.1334 11.1057 13.3349C10.4051 13.9479 9.70764 14.4271 9.18667 14.7527C8.92549 14.9159 8.70688 15.0417 8.55204 15.1274C8.47458 15.1703 8.41295 15.2032 8.36979 15.2258C8.3482 15.2372 8.33122 15.2459 8.31917 15.2521L8.30484 15.2594L8.30052 15.2616L8.29909 15.2623C8.29909 15.2623 8.29814 15.2628 8 14.6665C7.70186 15.2628 7.70145 15.2626 7.70145 15.2626L7.69948 15.2616L7.69516 15.2594L7.68083 15.2521C7.66878 15.2459 7.6518 15.2372 7.63021 15.2258C7.58705 15.2032 7.52542 15.1703 7.44796 15.1274C7.29312 15.0417 7.07452 14.9159 6.81333 14.7527C6.29236 14.4271 5.59492 13.9479 4.89433 13.3349C3.52116 12.1334 2 10.2975 2 7.99984V3.33317C2 3.05527 2.17238 2.80653 2.43258 2.70895L7.76592 0.708951ZM8 14.6665L7.70145 15.2626C7.88913 15.3564 8.11046 15.3566 8.29814 15.2628L8 14.6665ZM8 13.9083C8.12776 13.8359 8.29114 13.74 8.48 13.622C8.95903 13.3226 9.59492 12.8851 10.2277 12.3315C11.5212 11.1996 12.6667 9.70221 12.6667 7.99984V3.79517L8 2.04517L3.33333 3.79517V7.99984C3.33333 9.70221 4.47884 11.1996 5.77234 12.3315C6.40508 12.8851 7.04097 13.3226 7.52 13.622C7.70886 13.74 7.87224 13.8359 8 13.9083Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4714 6.1951C10.7318 6.45545 10.7318 6.87756 10.4714 7.13791L7.80474 9.80457C7.54439 10.0649 7.12228 10.0649 6.86193 9.80457L5.5286 8.47124C5.26825 8.21089 5.26825 7.78878 5.5286 7.52843C5.78895 7.26808 6.21106 7.26808 6.4714 7.52843L7.33333 8.39036L9.5286 6.1951C9.78895 5.93475 10.2111 5.93475 10.4714 6.1951Z"
    />
  </svg>
</template>
